import React, { useEffect, useState, useRef, useContext } from 'react';
import { PentanavContext } from '../context/pentanavContext';

import { FormControl, InputLabel, Select, MenuItem} from '@material-ui/core/';

import Testsite from '../classes/Testsite';
import Title from './Title';

import {useStyles} from '../styles/styles'

import mapboxgl from 'mapbox-gl';
// eslint-disable-next-line import/no-webpack-loader-syntax
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;
mapboxgl.accessToken = 'pk.eyJ1IjoicGVudGFtYXBmbG9yaWFuIiwiYSI6ImNrNm0yMWRxZDBlYXMzbXFzOGJmYW5hc2IifQ.LH1SPlbmgJ82PJh3pdJWnw';
const CENTER_SEETALERALPE = [14.56, 47.11]

export default function ReactMap(props) {

  //Props
  const profile = props.profile;
  console.log("Map Profile", profile)
  console.log("Map Props", props)

  const classes = useStyles()

  //Context
  const { user, project } = useContext(PentanavContext)

  const mapContainer = useRef(null);
  const map = useRef(null);
  const [center, setCenter] = useState(CENTER_SEETALERALPE);
  const [zoom, setZoom] = useState(11);

  const [testsite, setTestsite] = useState();
  const [testsites, setTestsites] = useState();

  /**
   * Fetch testsites
   * @param {*} project 
   */
   async function fetchTestsites(project){

    if(project){
      console.log("fetchTestsites", project.id)

      await fetch('/testsites/project/'+project.id).then((res) => {
        return res.json();
      }).then(async (testsitesJson) => {
        console.log('Testsites', testsitesJson);
        let testsites = []
        Promise.all(await testsitesJson.map(testsiteJson => {
          console.log('Testsite', testsiteJson);
          testsites.push(Testsite.fromJsonObj(testsiteJson))
        }))
        setTestsites(testsites)

      });
    }
    
  }

  /**
   * Select project
   * @param {*} project 
   */
   function onSelectTestsite(e){
    console.log("onSelectTestsite", e.target)
    testsites.map(testsite => {
      if(testsite.id == e.target.value){
        setTestsite(testsite)
        console.log("SET Center: ",testsite.center)
        //setCenter(testsite.center)
        map.current.flyTo({
          center: testsite.center
        });
      }
    })
  }

  useEffect(() => {

    //Fetch testsites
    fetchTestsites(project)
    
    //if (map.current) return; // initialize map only once

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: center,
      zoom: zoom,
      height: 100,
      width: 100
    });

    map.current.on('load', () => {

      let testsiteName = "seetaleralpe";
      let coordinates;
      switch(testsite){
        case Testsite.SEETALERALPE:
          testsiteName = "seetaleralpe";
          coordinates = [
            [14.530710000, 47.121460000],
            [14.604450000, 47.121460000],
            [14.604450000, 47.086990000],
            [14.530710000, 47.086990000]
          ]

          //Landcover
          map.current.addSource('landcover-seetaleralpe', {
            'type': 'image',
            'url': process.env.PUBLIC_URL+'/download/landcover_seetaleralpe.png',
            'coordinates': coordinates
          });
          map.current.addLayer({
            id: 'landcover-seetaleralpe',
            'type': 'raster',
            'source': 'landcover-seetaleralpe',
            'paint': {
            'raster-fade-duration': 0
            }
          });

          break;
        case Testsite.RWANDA:
          testsiteName = "rwanda";
          coordinates = [
            [29.172765483, -2.226051464],
            [29.283759404, -2.226051464],
            [29.283759404, -2.291591487],
            [29.172765483, -2.291591487]
          ]

          //Landcover
          map.current.addSource('landcover-rwanda', {
            'type': 'image',
            'url': process.env.PUBLIC_URL+'/download/landcover_rwanda_new.png',
            'coordinates': coordinates
          });
          map.current.addLayer({
            id: 'landcover-rwanda',
            'type': 'raster',
            'source': 'landcover-rwanda',
            'paint': {
            'raster-fade-duration': 0
            }
          });

          //Obstacles
          break;
      }

      //Add costmatrix (if profile known)
      if(profile){
        console.log("Map profile existiert")
        map.current.addSource('profile-costmatrix', {
          'type': 'image',
          //'url': process.env.PUBLIC_URL+'/download/'+project.name.toLowerCase()+'/'+testsiteName+'/'+profile.name.toLowerCase()+'/5m/0.png',
          'url': process.env.PUBLIC_URL+'/download/uninav/seetaleralpe/'+profile.name.toLowerCase()+'/5m/0.png',
          'coordinates': [
            [14.530710000, 47.121460000],
            [14.604450000, 47.121460000],
            [14.604450000, 47.086990000],
            [14.530710000, 47.086990000]
          ]
        });
        map.current.addLayer({
          id: 'profile-costmatrix',
          'type': 'raster',
          'source': 'profile-costmatrix',
          'paint': {
          'raster-fade-duration': 0
          }
        });
     }

      //Layerswitcher
      // After the last frame rendered before the map enters an "idle" state.
      /*map.current.on('idle', () => {
        
        // Enumerate ids of the layers.
        const toggleableLayerIds = ['landcover-layer'];
        
        // Set up the corresponding toggle button for each layer.
        for (const id of toggleableLayerIds) {
          // Skip layers that already have a button set up.
          if (document.getElementById(id)) {
          continue;
          }
        
          // Create a link.
          const link = document.createElement('a');
          link.id = id;
          link.href = '#';
          link.textContent = id;
          link.className = 'active';
          
          // Show or hide layer when the toggle is clicked.
          link.onclick = function (e) {
          const clickedLayer = this.textContent;
          e.preventDefault();
          e.stopPropagation();
          
          const visibility = map.current.getLayoutProperty(
            clickedLayer,
            'visibility'
          );
        
          // Toggle layer visibility by changing the layout object's visibility property.
          if (visibility === 'visible') {
            map.current.setLayoutProperty(clickedLayer, 'visibility', 'none');
            this.className = '';
          } else {
            this.className = 'active';
            map.current.setLayoutProperty(
              clickedLayer,
              'visibility',
              'visible'
            );
          }
        };
        
        const layers = document.getElementById('menu');
        if(layers) layers.appendChild(link);
        }
      });*/


    });

  },[]);

    return (
      <React.Fragment>
        <Title>Overview map</Title>
        <FormControl className={classes.formControl}>
          {/*<InputLabel id="input-testsite-label">Projects</InputLabel>*/}
          <Select
            labelId="input-testsite-label"
            value={testsite ? testsite.id : (testsites && testsites.length > 0) ? testsites[0].id : ""}
            onChange={onSelectTestsite}
          >
          {testsites ? testsites.map(t => (
            <MenuItem key={t.id} value={t.id}>{t.name}</MenuItem>
          )) : null}
          </Select>
        </FormControl>
        <div ref={mapContainer} className={classes.mapHeight}>
          <nav id="menu"></nav>
        </div>
      </React.Fragment>
    );
}