import React, { useEffect, useState, useContext } from 'react';
import { PentanavContext } from '../context/pentanavContext';

//import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import PentanavDrawer from '../components/PentanavDrawer';
import Copyright from '../components/Copyright';
import Title from '../components/Title';

import TIFF from 'tiff.js';
import { MenuItem } from '@material-ui/core';

import {useStyles} from '../styles/styles'

export default function CostmatrixPage(props) {

  //Context
  const { user, project } = useContext(PentanavContext)

  const [testsites, setTestsites] = useState();
  const [testsite, setTestsite] = useState();

  const [profiles, setProfiles] = useState();
  const [profile, setProfile] = useState();

  const [costmatrices, setCostmatrices] = useState();
  const [costmatrix, setCostmatrix] = useState();

  const resolutions = [
    {id: 1, value: 1, label: '1m'},
    {id: 2, value: 5, label: '5m'},
    {id: 3, value: 20, label: '20m'},
    {id: 4, value: 50, label: '50m'},
    {id: 5, value: 200, label: '200m'},
    {id: 6, value: 500, label: '500m'},
  ]

  const [resolution, setResolution] = useState();

  const classes = useStyles();
 
  useEffect(() => {
    console.log("CostmatrixPage useEffect", props)

    console.log("User:", user)
    console.log("Project:", project)

    //Testsites
    fetchTestsites(project)

    //Profiles
    fetchProfiles(project)

    //Costmatrices
    fetchCostmatrices(project)

  },[]);

  /**
   * Fetch testsites
   * @param {*} project 
   */
   function fetchTestsites(project){
    console.log("fetchTestsites", project)

    if(project){
      fetch('/testsites/project/'+project.id).then((res) => {
        return res.json();
      }).then((testsites) => {
        console.log('Testsites', testsites);
        setTestsites(testsites)

        setTestsite(testsites[0])
      });
    }
    
  }

  /**
   * Fetch profiles
   * @param {*} project 
   */
   function fetchProfiles(project){
    console.log("fetchProfiles", project)

    if(project){
      fetch('/profiles/project/'+project.id).then((res) => {
        return res.json();
      }).then((profiles) => {
        console.log('Profiles', profiles);
        setProfiles(profiles)

        setProfile(profiles[0])
      });
    }
    
  }

  /**
   * On select testsite for calculation
   * @param {*} e 
   */
   const onSelectTestsite = (e) => {

    testsites.map(t => {
      if(t.id == e.target.value){
        setTestsite(t)
      }
    })
  }


  /**
   * On select profile for calculation
   * @param {*} e 
   */
   const onSelectProfile = (e) => {

    profiles.map(p => {
      if(p.id == e.target.value){
        setProfile(p)
      }
    })
  }

  /**
   * Fetch costmatrices
   * @param {*} project 
   */
   function fetchCostmatrices(project){
    console.log("fetchCostmatrices", project)

    if(project){
      fetch('/costmatrices/project/'+project.id).then((res) => {
        return res.json();
      }).then((costmatrices) => {
        console.log('Costmatrices', costmatrices);
        setCostmatrices(costmatrices)

        setCostmatrix(costmatrices[0])
      });
    }
    
  }

  /**
   * On select costmatrix read TIFF image from filename
   * @param {*} costmatrix 
   */
  const onSelectCostmatrix = (e) => {

    costmatrices.map(c => {
      if(c.id == e.target.value){
        setCostmatrix(c)

        //File aus dem Download Ordner holen
        console.log("Project:", project.name)
        console.log("Testsite:", testsite.name)
        console.log("Profile:", profile.name)

        let testsiteName = "seetaleralpe";
        switch(testsite.id){
          case 1:
            testsiteName = "rwanda";
            break;
          case 2:
            testsiteName = "seetaleralpe";
            break;
        }

        //let tiffFile = '/download/'+project.id+'/'+profile.id+'/'+c.filename+".tif";
        let tiffFile = '/download/'+project.name.toLowerCase()+'/'+testsiteName+'/'+profile.name.toLowerCase()+'/'+c.filename+".tif";

        if(costmatrix.filename){
          var xhr = new XMLHttpRequest();
          xhr.responseType = "arraybuffer";
          xhr.open('GET', tiffFile);
      
          xhr.onload = function (e) {
            var arrayBuffer = this.response;
            TIFF.initialize({
              TOTAL_MEMORY: 16777216 * 10
            });
            var tiff = new TIFF({
              buffer: arrayBuffer
            })
      
            var dataUrI = tiff.toDataURL();
            document.getElementById("tiffImg").src = dataUrI;
          };
          xhr.send();
        }
      }
    })

  }

  /**
   * On select resolution for calculation
   * @param {*} resolution 
   */
   const onSelectResolution = (e) => {

    resolutions.map(r => {
      if(r.value == e.target.value){
        setResolution(r)
      }
    })
  }

  /**
 * Calculate cost matrix
 */
  const calcCostmatrix = (project, testsite, profile) => {
    let projectId = (project) ? project.id : 1
    let testsiteId = (testsite) ? testsite.id : 1
    let profileId = (profile) ? profile.id : 1

    console.log("calcCostmatrix: ", projectId, testsiteId, profileId)

    /*fetch('/calculations/'+profile_id).then((res) => {
      return res.json();
    }).then((json) => {
      console.log("Calculation Response: ", json);
    });*/

    //Costmatrix
    fetch('/calculations/'+projectId+"/"+testsiteId+'/'+profileId+'/'+resolution.value).then((res) => {
      return res.json();
    }).then((json) => {
      console.log("Calculation Response: ", json);

      fetchCostmatrices(project)

    });

    //Terrain
    /*fetch('/calculations/terrain/'+projectId+"/"+testsiteId+'/'+profileId+'/'+resolution.value).then((res) => {
      return res.json();
    }).then((json) => {
      console.log("Calculation Response: ", json);
    });*/
  }
  

    return (
      <div className={classes.root}>
        <CssBaseline />
        <PentanavDrawer user={user} title={"Costmatrix calulation"}/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={6} align="center" direction="column">
                <Grid item xs={12}>
                  <Title>Calculate cost matrix</Title>
                  <FormControl className={classes.formControl}>
                  <InputLabel id="input-profile-label">Profile</InputLabel>
                    <Select
                      labelId="input-profile-label"
                      value={profile ? profile.id : ""}
                      onChange={onSelectProfile}
                      width="100%">
                      {profiles ? profiles.map(profile => (
                        <MenuItem value={profile.id}>{profile.name}</MenuItem>
                      )): null}
                    </Select>
                  </FormControl>                
                </Grid>
                <Grid item xs={12}>
                <FormControl className={classes.formControl}>
                  <InputLabel id="input-testsite-label">Testsite</InputLabel>
                    <Select
                      labelId="input-testsite-label"
                      value={testsite ? testsite.id : ""}
                      onChange={onSelectTestsite}
                      width="100%">
                      {testsites ? testsites.map(testsite => (
                        <MenuItem value={testsite.id}>{testsite.name}</MenuItem>
                      )): null}
                    </Select>
                  </FormControl> 
                </Grid>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="input-resolution-label">Resolution</InputLabel>
                    <Select
                      labelId="input-resolution-label"
                      value={resolution ? resolution.value : ""}
                      onChange={onSelectResolution}
                      width="100%">
                      {resolutions ? resolutions.map(resolution => (
                        <MenuItem value={resolution.value}>{resolution.label}</MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button 
                    variant="contained"  
                    color="primary"
                    onClick={() => calcCostmatrix(project, testsite, profile)}
                  >
                    Calc costmatrix
                  </Button>
                  <div id='mydiv'></div>
                </Grid>
                <Grid item xs={12}>
                  <Title>Select costMatrix</Title>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="input-costmatrix-label">Costmatrix</InputLabel>
                    <Select
                      labelId="input-costmatrix-label"
                      value={costmatrix ? costmatrix.id : ""}
                      onChange={onSelectCostmatrix}
                      width="100%">
                      {costmatrices ? costmatrices.map(c => (
                        <MenuItem value={c.id}>{c.filename}</MenuItem>
                      )) : null}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <div>
                    <img id="tiffImg" width="100%"></img>
                  </div>
                </Grid>
            </Grid>
            <Box pt={4}>
                <Copyright />
            </Box>
          </Container>
          </main>
        </div>
    );
}