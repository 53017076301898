export default class Costparametertype {

    static LANDCOVER = 1;
    static LANDCOVER_2 = 11;
    static TOPOGRAPHY = 2;
    static OBSTACLES = 3;
    static ANTHROPOGENIC_FACTORS = 4;

    protected _ratings: any[] | undefined;

    constructor(
        protected _id: number, 
        protected _name: string, 
    ) {
    }

    set id(value){
        this._id = value;
    }

    get id(){
        return this._id;
    }

    get value(){
        return this._id;
    }

    set name(value){
        this._name = value;
    }

    get name(){
        return this._name;
    }

    set ratings(values){
        this._ratings = values;
    }

    get ratings(){
        return this._ratings;
    }

    addRating(value:any){
        if(this.ratings != null && this.ratings.length > 0){
            this.ratings.push(value);
        }else{
            this.ratings = [value];
        }
    }

    set label(value){
        this._name = value;
    }

    get label(){
        return this._name;
    }

}