import React, {useEffect} from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import Table from '@material-ui/core/Table';
import Box from '@material-ui/core/Box';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from './Title';

import PropTypes from 'prop-types';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ImpossibleIcon from '@material-ui/icons/Close';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Typography from '@material-ui/core/Typography';

const customIcons = {
  1: {
    icon: <ImpossibleIcon />,
    label: 'Impossible to route',
  },
  2: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  3: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  4: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  5: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  6: {
    icon: <SentimentVerySatisfiedIcon/>,
    label: 'Very Satisfied',
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
  root: {
    // ...
  },
  /*'icon-1': { color: '#d7191c' },
  'icon-2': { color: '#fdae61' },
  'icon-3': { color: '#ffffbf' },
  'icon-4': { color: '#a6d96a' },
  'icon-5': { color: '#1a9641' },*/
  'icon-1': { color: '#d73027' },
  'icon-2': { color: '#fc8d59' },
  'icon-3': { color: '#fee08b' },
  'icon-4': { color: '#d9ef8b' },
  'icon-5': { color: '#91cf60' },
  'icon-6': { color: '#1a9850' },
}));

/**#d73027
#fc8d59
#fee08b
#d9ef8b
#91cf60
#1a9850 */

export default function Ratings(props) {

  const classes = useStyles();
  const [iconFilled, setIconFilled] = React.useState(1)
  const [iconHover, setIconHover] = React.useState(classes[`icon-${Math.ceil(1)}`])

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log("ratings useEffect", props)
  },[]);

  return (
    <React.Fragment>
      <Title>Impact of {props.type.name}</Title>
      <Box>Please rate the impact of "{props.type.name}" factors to a successful routing from negative impact (1) to positive impact (5). If routing at a class is impossible select (x)</Box>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Rating</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.ratings ? props.ratings.map((rating) => (
            <TableRow key={rating.profile.name + "_" +rating.costparameter.id}>
              <TableCell>{rating.costparameter.name}</TableCell>
              <TableCell>
                <div className={classes.root}>
                  <Rating
                    classes={{
                      iconFilled: classes[`icon-${Math.ceil(rating.rating + 1)}`],
                      iconHover: iconHover
                    }}
                    name={"half-rating-"+rating.costparameter.id}
                    defaultValue={rating.rating + 1} 
                    precision={1}  
                    max={6}
                    getLabelText={(value) => customIcons[value].label}
                    IconContainerComponent={IconContainer}
                    onChangeActive={(event, newHover) => {
                      console.log("Hover: ", newHover)
                      setIconHover(classes[`icon-${Math.ceil(newHover)}`])
                      //classes[`icon-${Math.ceil(iconHover)}`]
                    }}
                    onChange={(event, newValue) => {
                      //setValue(newValue);
                      console.log("Neuer Wert - Project: " +rating.project.id+" Profile:" +rating.profile.id+" costparameter:"+rating.costparameter.id+": ",newValue)

                      setIconFilled(classes[`icon-${Math.ceil(newValue)}`])

                      //Rating auf -1 oder 1 bis 5 bringen
                      //let newRating = (newValue > 0) ? (newValue-1) : -1;

                      //Rating auf 0 (unmöglich) bis 5 (einfach) bringen
                      let newRating = newValue - 1;
                      rating.rating = newRating;


                      //UPDATE Rating
                      const requestOptions = {
                        method: 'PUT',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify({ rating: newRating })
                      };

                      fetch('/ratings/update/'+rating.project.id+'/'+rating.profile.id+'/'+rating.costparameter.id, requestOptions)
                      .then((res) => {
                        return res.json();
                      }).then((ratings) => {
                        console.log('Rating UPDATED', ratings);
                        //this.setState({ ratings: ratings });
                      })

                    }}
                  />
                </div>
              </TableCell>
            </TableRow>
          )):null}
        </TableBody>
      </Table>
      {/*<div className={classes.seeMore}>
        <Link color="primary" href="#" onClick={preventDefault}>
          See more orders
        </Link>
      </div>*/}
    </React.Fragment>
  );
}