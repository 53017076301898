import React from 'react';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';

import { useHistory } from "react-router-dom";

import Loader from 'react-loader-spinner';
import { usePromiseTracker, trackPromise } from 'react-promise-tracker';

import { Typography, Card, CardActionArea, CardActions, CardContent, CardMedia, Button } from '@material-ui/core';
import Testsite from '../classes/Testsite';

const useStyles = makeStyles({
  depositContext: {
    flex: 1,
  },
});

export default function ProfileCard({project, testsite, profile}) {

  //History
  const history = useHistory();

  const classes = useStyles();

   /**
   * Calculate cost matrix
   */
    const calcCostmatrix = (project, testsite, profile) => {
      let projectId = (project) ? project.id : 1
      let testsiteId = (testsite) ? testsite.id : Testsite.SEETALERALPE
      let profileId = (profile) ? profile.id : 1
  
      console.log("calcCostmatrix: ", projectId, testsiteId, profileId)

      let resolution = 5;
  
      //Calculation
      trackPromise(fetch('/calculations/'+projectId+"/"+testsiteId+'/'+profileId+'/'+resolution).then((res) => {
        return res.json();
      }).then((json) => {
        console.log("Calculation Response: ", json);
  
        //fetchCostmatrices(project)
  
      }));
  
      //Terrain
      /*fetch('/calculations/terrain/'+projectId+"/"+testsiteId+'/'+profileId+'/'+resolution.value).then((res) => {
        return res.json();
      }).then((json) => {
        console.log("Calculation Response: ", json);
      });*/
    }

    const LoadingIndicator = props => {
      const { promiseInProgress } = usePromiseTracker();
    
        return (
          promiseInProgress && (
          <div
            style={{
            width: "100%",
            height: "100",
            display: "flex",
            justifyContent: "center",
            alignItems: "center"
          }}
          >
            <Loader type="ThreeDots" color="primary" height="100" width="100" />
          </div>
          )
      );  
      }

  return (
    <Card className={classes.root}>
      <CardContent>
        <Typography gutterBottom variant="h5" component="h2">
          {profile ? profile.name : "Name"}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {profile ? profile.notice: "Notice"}
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          {profile ? "Created by "+profile.createdBy: "createdBy"}
        </Typography>
        <LoadingIndicator/>
        
      </CardContent>
      <CardActions>
        <Button size="small" color="primary" onClick={() => { history.push('/profiles/edit/'+profile.id) }}>
          Edit
        </Button>
        <Button size="small" color="primary" onClick={() => calcCostmatrix(project, testsite, profile)}>
          Calculate costmatrix
        </Button>
      </CardActions>
    </Card>
  );
}