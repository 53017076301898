import React, { useEffect, useContext, useState } from 'react';
import { PentanavContext } from '../context/pentanavContext';

import { useHistory } from "react-router-dom";

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Copyright from '../components/Copyright';
import { useLocation } from 'react-router';
import queryString from 'query-string';

import User from '../classes/User';
import Project from '../classes/Project';

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignInPage(props) {

  //Context
  const { user, dispatchUser, projects, dispatchProjects, project, dispatchProject } = useContext(PentanavContext)

  //Params
  console.log(props)
  const params = queryString.parse(props.location.search)
  console.log("Params", params)

  const [email, setEmail] = useState()
  const [password, setPassword] = useState();

  //History
  const history = useHistory();

  //Style
  const classes = useStyles();
 
  useEffect(() => {
    console.log("SignInPage useEffect", props)

     //Check Logged in
     //loginUser(params)
     fetchUser(1)

  },[]);

  /**
   * Fetch user
   * @param {*} id 
   */
  async function fetchUser(id){
    console.log("fetchUser:", id)

    await fetch('/users/'+id).then((res) => {
      return res.json();
    }).then(async (users) => {

      console.log('Users', users);

      if(users){
        //User
        let user = User.fromJsonObj(users[0]);
        console.log('User', user);

        //Projects
        let projects = await fetchProjects(user)
        console.log("User Projects: ", projects)

        //Set user
        dispatchUser({ type: "SET_USER", user })

        //Start
        history.push('/home/')
      }
      
    });
  }

  /**
   * Fetch projects
   * @param {*} userId 
   */
  async function fetchProjects(user){

  if(user){
    console.log("fetchProjects", user.id)

    await fetch('/projects/user/'+user.id).then((res) => {
      return res.json();
    }).then(async (projectsJson) => {
      console.log('Projects', projectsJson);
      let projects = []
      Promise.all(await projectsJson.map(projectJson => {
        console.log('Project', projectJson);
        projects.push(Project.fromJsonObj(projectJson))
      }))

      dispatchProjects({ type: "SET_PROJECTS", projects })
      console.log("Projects: ", projects)

      let project = projects[0];
      console.log("Project: ", project)
      dispatchProject({ type: "SET_PROJECT", project })

      //Theme
      //theme.palette.primary = project.color;

      //Profiles
      //if(user._organization && project.id) fetchProfiles(user._organization._id, project.id)

    });
  }

  }

  /**
   * Login user
   * @param {*} credentials 
   * @returns 
   */
  async function loginUser(params) {
    fetch('/auth/'+params.email+'/'+params.password)
      .then(data => data.json())
      .then(usersJson => {
        console.log("LoginResponse", usersJson);
        if(usersJson.length > 0){
          let user = usersJson[0]
          console.log("JSON: ", user )
          fetchUser(user.id)
        }
      })
  }

  const onChangeEmail = (e) => {
    console.log(e)
    setEmail(e.target.value)
  }

  const onChangePassword = (e) => {
    console.log(e)
    setPassword(e.target.value)
  }

  function handleSubmit(e) {
    console.log('An email was submitted: ', email);
    loginUser({email: email, password: password})
    e.preventDefault();
  }

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email || ''}
            onChange={onChangeEmail}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password || ''}
            onChange={onChangePassword}
          />
          <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
      <Box mt={8}>
        <Copyright />
      </Box>
    </Container>
  );
}