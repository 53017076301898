import React, { useEffect, useState, useContext } from 'react';
import { PentanavContext } from '../context/pentanavContext';

import { useHistory } from "react-router-dom";

//import Select from 'react-select';
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import {Paper, Tabs, Tab, Box} from '@material-ui/core';

import PentanavDrawer from '../components/PentanavDrawer';
import Copyright from '../components/Copyright';
import ProfileCard from '../components/ProfileCard'
import ReactMap from '../components/ReactMap';
import Ratings from '../components/Ratings';

import Profile from '../classes/Profile';
import Costparametertype from '../classes/Costparametertype';

import queryString from 'query-string';

import {useStyles} from '../styles/styles'

function TabPanel(props) {
  const { children, value, index, ...other } = props;  return (
    <div {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

export default function ProfilePage(props) {

  //Context
  const { user, project } = useContext(PentanavContext)

  //Params
  const params = queryString.parse(props.location.search)
  console.log("Params Profile: ", params.id)

  //History
  const history = useHistory();

  const [profile, setProfile] = useState();
  const [costparametertypes, setCostparametertypes] = useState();

  const [testsite, setTestsite] = useState(null);

  const classes = useStyles();

  const [tabValue, setTabValue] = React.useState(0);  
  
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
 
  useEffect(() => {
    console.log("CostmatrixPage useEffect", props)

    console.log("User:", user)
    console.log("Project:", project)

    //Profiles
    /*if(user && project){
      fetchProfiles(user.organization, project)
    }*/

    fetchProfile(params.id)

  },[]);

  /**
   * Fetch profile
   * @param {*} id 
   */
   function fetchProfile(id){
    console.log("fetchProfile", id)

    fetch('/profiles/'+id).then((res) => {
      return res.json();
    }).then((profiles) => {
      let profile = profiles[0];
      console.log('setProfile', profile);
      console.log('setProfile Objc', Profile.fromJsonObj(profile));
      setProfile(Profile.fromJsonObj(profile))

      fetchRatings(project, profile)
    });
  
  }

     /**
   * Fetch all ratings by project id and profile id
   * @param {*} project
   * @param {*} profile
   */
  function fetchRatings(project, profile){

    console.log("fetchRatings", project ,profile)
    if(project && profile){
      let project_id = project.id;
      let profile_id = profile.id;
      if(project_id && project_id > 0 && profile_id && profile_id > 0){

        let types = [];
        types.push(new Costparametertype(Costparametertype.LANDCOVER, "Landcover I"));
        types.push(new Costparametertype(Costparametertype.LANDCOVER_2, "Landcover II"));
        types.push(new Costparametertype(Costparametertype.TOPOGRAPHY, "Topography"));
        types.push(new Costparametertype(Costparametertype.OBSTACLES, "Obstacles"));
        types.push(new Costparametertype(Costparametertype.ANTHROPOGENIC_FACTORS, "Anthropogenic factors"));
  
        fetch('/ratings/'+project_id+'/'+profile_id).then((res) => {
          return res.json();
        }).then((ratings) => {
          console.log('Ratings', ratings);

          ratings.map(rating => {
            console.log("Rating Type: ", rating.costparameter.type_id)
            switch(rating.costparameter.type_id){
              case Costparametertype.LANDCOVER:
                if(rating.costparameter.class_id <= 7){
                  types[0].addRating(rating);
                }else{
                  types[1].addRating(rating);
                }
                break;
              case Costparametertype.TOPOGRAPHY:
                types[2].addRating(rating);
                break;
              case Costparametertype.OBSTACLES:
                types[3].addRating(rating);
                break;
              case Costparametertype.ANTHROPOGENIC_FACTORS:
                types[4].addRating(rating);
                break;
            }
          })

          setCostparametertypes(types)

        })
      } 
    }
    
  }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <PentanavDrawer user={user} title={profile ? profile.name : "Navigation profile"}/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Tabs value={tabValue} onChange={handleTabChange}>
            <Tab label="Overview" />
            <Tab label="Ratings" />
          </Tabs>
          <Container>
            <TabPanel value={tabValue} index={0}>
              <Grid container spacing={3}>              
                {/** Profile */}
                <Grid item xs={6}>
                  <ProfileCard profile={profile}></ProfileCard>
                </Grid>               
                
                {/** Calculation */}
                {/*<Grid item xs={6}>
                  <CalculationCard profile={profile}></CalculationCard>
                </Grid>*/}
                {/* Map */}
                {profile ? (
                <Grid item xs={6} >
                  <Paper className={classes.paper}>
                    <ReactMap project={project} testsite={testsite} profile={profile}/>
                  </Paper>
                </Grid>
                ): null}
              </Grid>
              <Box pt={4}>
                  <Copyright />
              </Box>
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
                <Grid container spacing={3}>
                  {costparametertypes ? costparametertypes.map(type => (
                  <Grid item xs={6}>
                    <Paper className={classes.paper}>
                        <Ratings type={type} ratings={type.ratings}/>
                    </Paper>
                  </Grid>
                  )):null}
                </Grid>
            </TabPanel>
          </Container>
        </main>
      </div>
    );
}