import React, {useState, useContext} from 'react';
import { PentanavContext } from '../context/pentanavContext';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { CardHeader, Avatar, FormControl, InputLabel, Select, MenuItem} from '@material-ui/core/';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';

import { useHistory } from "react-router-dom";

import {Star as StarIcon, Menu as MenuIcon, ViewModule as ViewModuleIcon, ChevronLeft as ChevronLeftIcon, ChevronRight as ChevronRightIcon, List as ListIcon, Commute as CommuteIcon} from '@material-ui/icons';


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function PentanavDrawer(props) {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  //History
  const history = useHistory();

  //Context
  const { user, projects, project, dispatchProject } = useContext(PentanavContext)

  //Project data
  const [title, setTitle] = useState(props.title);

  /**
   * Select project
   * @param {*} project 
   */
   function onSelectProject(e){
    console.log("onSelectProject", e.target)
    projects.map(project => {
      if(project.id == e.target.value){
        dispatchProject({ type: "SET_PROJECT", project })

        history.push('/home/')
      }
    })
  }

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: open,
        })}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: open,
            })}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap>
            {title}
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
        {user && open ? (
            <CardHeader 
              onClick={() => history.push('/home/')}
              title={user.fullname} 
              subheader={user.organization ? user.organization.name: null}
              avatar={
              <Avatar aria-label="recipe" className={classes.avatar}>
                {user.avatar}
              </Avatar>}
            >
            </CardHeader>
            ) : (<CardHeader 
            onClick={() => history.push('/home/')}
            title="PentaNav User"
            avatar={
            <Avatar aria-label="recipe" className={classes.avatar}>
              U
            </Avatar>}
          >
          </CardHeader>)}  
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
        <ListItem button onClick={() => history.push('/home/')}>
              <ListItemIcon>
                <ListIcon />
              </ListItemIcon>
              {projects && projects.length == 1 ? 
                (<ListItemText primary={projects[0].name} />)
              :
              (<FormControl className={classes.formControl}>
                {/*<InputLabel id="input-project-label">Projects</InputLabel>*/}
                <Select
                  labelId="input-project-label"
                  value={project ? project.id : ""}
                  onChange={onSelectProject}
                >
                {projects ? projects.map(p => (
                  <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                )) : null}
                </Select>
              </FormControl>)
              }
            </ListItem>
            <ListItem button onClick={() => history.push('/ratings/')}>
                <ListItemIcon>
                <StarIcon />
                </ListItemIcon>
                <ListItemText primary="Ratings" />
            </ListItem>
            <ListItem button onClick={() => history.push('/costmatrix/')}>
                <ListItemIcon>
                <ViewModuleIcon />
                </ListItemIcon>
                <ListItemText primary="Costmatrix" />
            </ListItem>
            <ListItem button onClick={() => history.push('/profiles/')}>
                <ListItemIcon>
                <CommuteIcon />
                </ListItemIcon>
                <ListItemText primary="Profiles" />
            </ListItem>
        </List>
      </Drawer>
    </div>
  );
}