import React, { createContext, useEffect, useReducer } from "react"
import Project from "../classes/Project"
import User from "../classes/User"
import { reducer } from "./reducer"

export const PentanavContext = createContext()

const PentanavProvider = ({ children }) => {

  const [user, dispatchUser] = useReducer(reducer, null, () => {
    const localData = localStorage.getItem('user')
    return localData ? User.fromStringObj(JSON.parse(localData)) : null
  })

  const [project, dispatchProject] = useReducer(reducer, null, () => {
    const localData = localStorage.getItem('project')
    return localData ? Project.fromStringObj(JSON.parse(localData)) : null
  });

  const [projects, dispatchProjects] = useReducer(reducer, null);


  useEffect(() => {
    console.log("pentanavContext useEffect")
    console.log("Context User: ", user)
    if(user) localStorage.setItem('user', JSON.stringify(user))
  },[user]);

  useEffect(() => {
    console.log("pentanavContext useEffect")
    console.log("Context Project: ", project)
    if(project) localStorage.setItem('project', JSON.stringify(project))
  },[project]);
  /*const savePentanav = article => {
    const newPentanav = {
      id: Math.random(), // not really unique but it's just an example
      title: article.title,
      body: article.body,
    }
    setPentanavs([...articles, newPentanav])
  }*/
  return (
    <PentanavContext.Provider value={{ user, dispatchUser, projects, dispatchProjects, project, dispatchProject }}>
      {children}
    </PentanavContext.Provider>
  )
}

export default PentanavProvider