import React from 'react';
//import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableHead, TableRow , CardActions, Button} from '@material-ui/core';

import {Edit as EditIcon, Delete as DeleteIcon} from '@material-ui/icons';

import Title from './Title';

import { useHistory, Link } from "react-router-dom";

function preventDefault(event) {
  event.preventDefault();
}

const useStyles = makeStyles((theme) => ({
  seeMore: {
    marginTop: theme.spacing(3),
  },
}));

export default function Profiles({profiles}) {

  //History
  const history = useHistory();

  const classes = useStyles();

  /*const tableRowClick = () => {
    return {
        onClick: () => history.push("/ratings/?"), // your onClick handler
        style: {
               cursor: 'pointer'
        },
       }
    }*/
  
  const deleteProfile = (profile) =>{
    console.log("Delete profile", profile)

    fetch('/profiles/delete/'+profile.id).then((res) => {
        return res.json();
      }).then((json) => {
        console.log('Delete result JSON', json);
      });
  }

  const editProfileClicked = (e) => {

  }

  return (
    <React.Fragment>
      <Title>Navigation profiles</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Short name</TableCell>
            <TableCell>Creator</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {profiles ? profiles.map((profile) => (
            <TableRow key={profile.value} hover={true} onClick={() => history.push("/profiles/?id="+profile.id)}>
              <TableCell>{profile.id}</TableCell>
              <TableCell>{profile.name}</TableCell>
              <TableCell>{profile.shortName}</TableCell>
              <TableCell>{profile.creator.fullnameShort}</TableCell>
              <TableCell>
                <EditIcon hover={true} onClick={(e) => {
                  e.stopPropagation();
                  history.push("/profiles/edit/"+profile.id)}}></EditIcon>
                {/*<DeleteIcon hover={true} onClick={(e) => {
                  e.stopPropagation();
                  deleteProfile(profile)}}></DeleteIcon>*/}
              </TableCell>
            </TableRow>
          )):null}
        </TableBody>
      </Table>
      <CardActions>
        <Button size="small" color="primary" onClick={() => history.push("/profiles/add/")}>
          Add profile
        </Button>
      </CardActions>
    </React.Fragment>
  );
}