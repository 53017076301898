import React from 'react';
import ReactDOM from 'react-dom';
//import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/css/bootstrap.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

require('react-web-vector-icons/fonts');

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4A8376" // This is an orange looking color                
    },      
    secondary: {
      main: "#4A8376" //Another orange-ish color                
    }
  },
  //fontFamily: font // as an aside, highly recommend importing roboto font for Material UI projects! Looks really nice
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
