export default class Testsite {

    static RWANDA = 1;
    static SEETALERALPE = 2;

    constructor(
        protected _id: number, 
        protected _name: string, 
        protected _bbox: string, 
    ) {
    }

    set id(value){
        this._id = value;
    }

    get id(){
        return this._id;
    }

    get value(){
        return this._id;
    }

    set name(value){
        this._name = value;
    }

    get name(){
        return this._name;
    }

    set bbox(value){
        this._bbox = value;
    }

    get bbox(){
        return this._bbox;
    }

    get center(){
        let center = [0,0];
        if(this.bbox){
            let coords = this.bbox.split(',');
            console.log("Coords: ", coords)
            let lon = (parseFloat(coords[0])+parseFloat(coords[2]))/2;
            let lat = (parseFloat(coords[1])+parseFloat(coords[3]))/2;
            center = [lon, lat]
        }
        console.log("Center: ", center)

        return center;
    }

    static fromJsonObj(obj: any){
        return new Testsite (obj.id, obj.name, obj.bbox);
    }

}