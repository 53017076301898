import User from "./User";

export default class Creator extends User {

    constructor(
        protected _id: number, 
        protected _givenname: string, 
        protected _surname: string, 
    ) {
        super(_id, "", "", _givenname, _surname, undefined)
    }

    static fromJsonObj(obj: any){
        return new Creator (obj.id, obj.givenname, obj.surname);
    }
}