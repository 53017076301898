import React, { useEffect, useState, useContext } from 'react';
import { PentanavContext } from '../context/pentanavContext';

import { useHistory, useParams } from "react-router-dom";

import {Container, FormControl, Grid, Paper, Box, Button, TextField, Typography, Card, CardActionArea, CardActions, CardContent, Divider} from '@material-ui/core'

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import PentanavDrawer from '../components/PentanavDrawer';
import Copyright from '../components/Copyright';

import {useStyles} from '../styles/styles'

export default function ProfilePage(props) {

  //Params
  const {profileId} = useParams();

  //Context
  const { user, project } = useContext(PentanavContext)

  //History
  const history = useHistory();

  const [name, setName] = useState();
  const [notice, setNotice] = useState();

  const classes = useStyles();
 
  useEffect(() => {
    console.log("AddProfilePage useEffect", props)

    console.log("Profile:", profileId)

    if(profileId > 0){
      fetchProfile(profileId)
    }

  },[]);

  /**
   * Fetch profiles
   * @param {*} profileId 
   */
   function fetchProfile(profileId){
    console.log("fetchProfile", profileId)

    if(profileId){
      fetch('/profiles/'+profileId).then((res) => {
        return res.json();
      }).then((profiles) => {
        let profile = profiles[0]
        if(profile){
          setName(profile.name)
          setNotice((profile.notice) ? profile.notice : "")
        }      
      });
    }
    
  }

  const handleNameChange = (e) => {
    setName(e.target.value)
  }

  const handleNoticeChange = (e) => {
    setNotice(e.target.value)
  }

  /**
   * 
   */
  const saveProfile = () => {
    console.log("saveProfile", name, notice)

    //UPDATE profile
    const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ name: name, notice: notice, user: user, project: project })
    };

    if(profileId){
      fetch('/profiles/update/'+profileId, requestOptions)
      .then((res) => {
        return res.json();
      }).then((profile) => {
        console.log('Profile updated', profile);
      })
    }else{
      fetch('/profiles/add/', requestOptions)
      .then((res) => {
        return res.json();
      }).then((profile) => {
        console.log('Profile created', profile);  
      })
    }

    history.goBack()
    
  }

    return (
      <div className={classes.root}>
        <CssBaseline />
        <PentanavDrawer user={user} title={"Add navigation profile"}/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
              <Card>
                <CardContent align="left" direction="column">
                    <div>
                      <TextField id="profile-name" label="Name" value={name || ''} onChange={handleNameChange} />
                    </div>
                    <div>
                      <TextField id="profile-notice" label="Notice" value={notice || ''} onChange={handleNoticeChange} />
                    </div>
                </CardContent>
                <CardActions>
                  <Button size="small" color="primary" onClick={saveProfile}>
                    Save profile
                  </Button>
                  <Button size="small" color="primary" onClick={() => history.goBack()}>
                    Cancel
                  </Button>
                </CardActions>
              </Card>
            <Box pt={4}>
                <Copyright />
            </Box>
          </Container>
          </main>
        </div>
    );
}