import Organization from "./Organization";
import Creator from "./Creator";

export default class Profile {

    constructor(
        protected _id: number,
        protected _organization: Organization,
        protected _creator: Creator,
        protected _name: string, 
        protected _shortName: string, 
        protected _notice: string, 
        protected _color: string, 
    ) {
    }

    set id(value){
        this._id = value;
    }

    get id(){
        return this._id;
    }

    get value(){
        return this._id;
    }

    set organization(value : Organization){
        this._organization = value;
    }

    get organization(){
        return this._organization;
    }

    set creator(value : Creator){
        this._creator = value;
    }

    get creator(){
        return this._creator;
    }

    get createdBy(){
        let createdBy = "";
        if(this.creator && this.organization){
            createdBy = this.creator.fullname + " ("+this.organization.name+")"
        }
        return createdBy;
    }

    set name(value){
        this._name = value;
    }

    get name(){
        return this._name;
    }

    set shortName(value){
        this._shortName = value;
    }

    get shortName(){
        return this._shortName;
    }

    set notice(value){
        this._notice = value;
    }

    get notice(){
        return this._notice;
    }

    set color(value){
        this._color = value;
    }

    get color(){
        return this._color;
    }

    set label(value){
        this._name = value;
    }

    get label(){
        return this._name;
    }

    static fromJsonObj(obj: any){
        return new Profile (obj.id, Organization.fromJsonObj(obj.organization), Creator.fromJsonObj(obj.creator), obj.name, obj.short_name, obj.notice, obj.color);
    }

}