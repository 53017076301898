import React, {useEffect} from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";

//Provider
import PentanavProvider from "./context/pentanavContext"

//pages
import SignInPage from './pages/SignInPage';
import DashboardPage from './pages/DashboardPage';
import ProfilePage from './pages/ProfilePage';
import AddProfilePage from './pages/AddProfilePage';
import RatingPage from './pages/RatingPage';
import CostmatrixPage from './pages/CostmatrixPage';
import NotFoundPage from './pages/404';

function App() {

  useEffect(() => {
    console.log("App.js useEffect")

    //Check Logged in

    //Nutzer überprüfen

    //User setzen (Fetch user from database and set)
  
  },[]);

  return (
    <PentanavProvider>
      <React.Fragment>
        <Router>
            <Switch>
              <Route exact 
                path="/home/" 
                render={(props) => (
                  <DashboardPage {...props}/>
                )}/>
              <Route exact 
                path="/profiles/" 
                render={(props) => (
                  <ProfilePage {...props}/>
                )}/>
              <Route exact 
                path="/profiles/add" 
                render={(props) => (
                  <AddProfilePage {...props}/>
                )}/>
              <Route exact 
                path="/profiles/edit/:profileId" 
                render={(props) => (
                  <AddProfilePage {...props}/>
                )}/>
              <Route exact 
                path="/costmatrix/" 
                render={(props) => (
                  <CostmatrixPage {...props}/>
                )}/>
              <Route exact 
                path="/ratings/" 
                render={(props) => (
                  <RatingPage {...props}/>
                )}/>
              <Route exact 
                path="/" 
                render={(props) => (
                  <SignInPage {...props}/>
                )}/>
              <Route exact path="/404" component={NotFoundPage} />
              <Redirect to="/404" />
            </Switch>
        </Router>
      </React.Fragment>
    </PentanavProvider>
  );
}

export default App;
