import Organization from "./Organization";
import Project from "./Project";

export default class User {

    protected _projects: Project[] | undefined

    constructor(
        protected _id: number, 
        protected _email: string, 
        protected _password: string, 
        protected _givenname: string, 
        protected _surname: string, 
        protected _organization: Organization | undefined
    ) {
    }

    set id(value){
        this._id = value;
    }

    get id(){
        return this._id;
    }

    set email(value){
        this._email = value;
    }

    get email(){
        return this._email;
    }

    set password(value){
        this._password = value;
    }

    get password(){
        return this._password;
    }

    set givenname(value){
        this._givenname = value;
    }

    get givenname(){
        return this._givenname;
    }

    set surname(value){
        this._surname = value;
    }

    get surname(){
        return this._surname;
    }

    get fullname(){
        return this.givenname + " " + this.surname;
    }

    get fullnameShort(){
        return this.givenname.substring(0,1) + ". " + this.surname;
    }

    get avatar(){
        return this.givenname.substring(0,1)
    }

    set organization(value){
        this._organization = value
    }

    get organization(){
        return this._organization
    }

    set projects(value){
        this._projects = value
    }

    get projects(){
        return this._projects;
    }

    static fromJsonString(json: string){
        var obj = JSON.parse(json);
        return new User (obj.id, obj.email, obj.password, obj.givenname, obj.surname, new Organization(obj.organization.id, obj.organization.name, obj.organization.color));
    }

    static fromJsonObj(obj: any){
        return new User (obj.id, obj.email, obj.password, obj.givenname, obj.surname, new Organization(obj.organization.id, obj.organization.name, obj.organization.color));
    }

    static fromStringObj(obj: any){
        let user = null;
        if(obj) new User (obj._id, obj._email, obj._password, obj._givenname, obj._surname, new Organization(obj._organization._id, obj._organization._name, obj._organization._color));
        return user;
    }
}