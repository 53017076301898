export const reducer = (state, action) => {
  console.log("Reducer: ", action)
    switch (action.type) {
      case "SET_USER":
        return action.user
      case "SET_PROJECT":
        return action.project  
      case "SET_PROJECTS":
        return action.projects  
      default:
        return state
    }
  }
