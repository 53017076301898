import React, {useEffect, useState, useContext} from 'react';
import { PentanavContext } from '../context/pentanavContext';

import { useParams} from 'react-router-dom';

import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

//Components
import ReactMap from '../components/ReactMap';
import Profiles from '../components/Profiles';
import MiniDrawer from '../components/PentanavDrawer';
import PentanavDrawer from '../components/PentanavDrawer';

import Copyright from '../components/Copyright';
import User from '../classes/User';
import Project from '../classes/Project';
import Profile from '../classes/Profile';

import {useStyles} from '../styles/styles'

export default function Dashboard(props) {

  //Context
  const { user, project } = useContext(PentanavContext)

  const [profiles, setProfiles] = useState();

  //Theme
  const theme = useTheme();

  const classes = useStyles();
  const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
  const mapHeightPaper = clsx(classes.paper, classes.mapHeight);


  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    console.log("dashboard useEffect")

    //SET User 
    console.log("Dashboard User: ", user)

    //Profiles
    if(user && project) fetchProfiles(user._organization._id, project.id)


  },[project]);

    /**
   * Fetch profiles
   * @param {*} projectId 
   */
     function fetchProfiles(organizationId, projectId){
      console.log("fetchProfiles", organizationId, projectId)
  
      fetch('/profiles/organization/'+organizationId+'/'+projectId).then((res) => {
        return res.json();
      }).then(async (profilesJson) => {
        console.log('Profiles JSON', profilesJson);

        let profiles = []
        Promise.all(await profilesJson.map(profileJson => {
          console.log('Profile JSON', profileJson);
          profiles.push(Profile.fromJsonObj(profileJson))
        }))

        setProfiles(profiles)
        console.log('Profiles', profiles);

      });
    }

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/*<PentanavDrawer user={user} title={"Dashboard"}/>*/}
      <PentanavDrawer title={project ? project.name + " Dashboard" : "Dashboard"}/>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Map */}
            <Grid item xs={6} >
              <Paper className={classes.paper}>
                  <ReactMap/>
              </Paper>
            </Grid>
            {/* Profiles */}
            <Grid item xs={6}>
              <Paper className={classes.paper}>
                {profiles ? (
                <Profiles profiles={profiles} />
                ) : <div>No profiles</div>}
              </Paper>
            </Grid>
          </Grid>
          <Box pt={4}>
              <Copyright />
          </Box>
      </Container>
      </main>
    </div>
  );
}