export default class Organization {

    //static UNINAV = 1;
    //static ROBONAV = 2;

    constructor(
        protected _id: number, 
        protected _name: string, 
        protected _color: string, 
    ) {
    }

    set id(value){
        this._id = value;
    }

    get id(){
        return this._id;
    }

    get value(){
        return this._id;
    }

    set name(value){
        this._name = value;
    }

    get name(){
        return this._name;
    }

    set color(value){
        this._color = value;
    }

    get color(){
        return this._color;
    }

    set label(value){
        this._name = value;
    }

    get label(){
        return this._name;
    }

    static fromJsonObj(obj: any){
        return new Organization (obj.id, obj.name, obj.color);
    }

}