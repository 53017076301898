import React, { useEffect, useState , useContext} from 'react';
import { PentanavContext } from '../context/pentanavContext';

import { CssBaseline, Container, Grid, Paper, Box, MenuItem, FormControl, InputLabel, Select} from '@material-ui/core';

import PentanavDrawer from '../components/PentanavDrawer';
import Ratings from '../components/Ratings';
import Copyright from '../components/Copyright';
import Title from '../components/Title';

import Costparametertype from '../classes/Costparametertype';

import queryString from 'query-string';

import {useStyles} from '../styles/styles'

export default function RatingPage(props) {

  //Context
  const { user, projects, project } = useContext(PentanavContext)

  //Params
  const params = queryString.parse(props.location.search)
  console.log("Params Profile: ", params.p)

  const [profile, setProfile] = useState({id: params.p});
  const [profiles, setProfiles] = useState();

  const [costparametertypes, setCostparametertypes] = useState();

  const classes = useStyles();

 
  useEffect(() => {
    console.log("RatingPage useEffect", props)

    //Profiles
    fetchProfiles(project)

  },[]);

    /**
   * Fetch profiles
   * @param {*} project 
   */
     function fetchProfiles(project){
      console.log("fetchProfiles", project)
  
      if(project){
        fetch('/profiles/project/'+project.id).then((res) => {
          return res.json();
        }).then((profiles) => {
          console.log('Profiles', profiles);
          setProfiles(profiles)
  
          //Initial profile
          if(profiles){

            profiles.map(p => {
              if(p.id == profile.id){
                setProfile(p)
              }
            })
  
            //Ratings
            fetchRatings(project, profile);
          }
  
        });
      }
      
    }

     /**
   * Fetch all ratings by project id and profile id
   * @param {*} project
   * @param {*} profile
   */
  function fetchRatings(project, profile){

    console.log("fetchRatings", project ,profile)
    if(project && profile){
      let project_id = project.id;
      let profile_id = profile.id;
      if(project_id && project_id > 0 && profile_id && profile_id > 0){

        let types = [];
        types.push(new Costparametertype(Costparametertype.LANDCOVER, "Landcover I"));
        types.push(new Costparametertype(Costparametertype.LANDCOVER_2, "Landcover II"));
        types.push(new Costparametertype(Costparametertype.TOPOGRAPHY, "Topography"));
        types.push(new Costparametertype(Costparametertype.OBSTACLES, "Obstacles"));
        types.push(new Costparametertype(Costparametertype.ANTHROPOGENIC_FACTORS, "Anthropogenic factors"));
  
        fetch('/ratings/'+project_id+'/'+profile_id).then((res) => {
          return res.json();
        }).then((ratings) => {
          console.log('Ratings', ratings);

          ratings.map(rating => {
            console.log("Rating Type: ", rating.costparameter.type_id)
            switch(rating.costparameter.type_id){
              case Costparametertype.LANDCOVER:
                if(rating.costparameter.class_id <= 7){
                  types[0].addRating(rating);
                }else{
                  types[1].addRating(rating);
                }
                break;
              case Costparametertype.TOPOGRAPHY:
                types[2].addRating(rating);
                break;
              case Costparametertype.OBSTACLES:
                types[3].addRating(rating);
                break;
              case Costparametertype.ANTHROPOGENIC_FACTORS:
                types[4].addRating(rating);
                break;
            }
          })

          setCostparametertypes(types)

        })
      } 
    }
    
  }

   /**
   * Select profile
   * @param {*} profile 
   */
    function onSelectProfile(e){
      console.log("onSelectProfile", e)

      profiles.map(profile => {
        if(e.target.value == profile.id){
          setProfile(profile)

          fetchRatings(project, profile)
        }
      })
    }


    return (
      <div className={classes.root}>
        <CssBaseline />
        <PentanavDrawer user={user} title={"Navigation profile"}/>
        <main className={classes.content}>
          <div className={classes.appBarSpacer} />
          <Container maxWidth="lg" className={classes.container}>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Title>Navigation profile</Title>
                  <FormControl className={classes.formControl}>
                    <Select
                      value={profile ? profile.id : (profiles && profiles.length > 0) ? profiles[0].id : ""}
                      onChange={onSelectProfile}
                      width="100%">
                      {profiles ? profiles.map(profile => (
                        <MenuItem value={profile.id}>{profile.name}</MenuItem>
                      )): null}
                    </Select>
                  </FormControl>
                </Grid>
                {costparametertypes ? costparametertypes.map(type => (
                <Grid item xs={6}>
                  <Paper className={classes.paper}>
                      <Ratings type={type} ratings={type.ratings}/>
                  </Paper>
                </Grid>
                )):null}
            </Grid>
            <Box pt={4}>
                <Copyright />
            </Box>
          </Container>
          </main>
        </div>
    );
}